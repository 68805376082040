<template>
	<pui-datatable
		:modelName="modelName"
		:showCreateBtn="false"
		:showDeleteBtn="false"
		:pageLength="20"
		:lengthMenu="[20]"
		:externalFilter="activeFilter"
		:modelColumnDefs="columnDefsActive"
		:masterDetailColumnVisibles="activeColumns"
		:showRowActions="false"
		:readOnly="true"
		:navigableDetail="false"
	>
	</pui-datatable>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import { isSuperAdmin, getDateByFormat } from '@/api/common';

//import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'PmNotificationsGrid',
	data() {
		return {
			modelName: 'pmnotifications',
			isSuperAdmin: false,

			// Active notifications
			activeColumns: {
				code: true,
				ruleacronym: true,
				startdate: true,
				providername: true,
				isaverage: true
				//source: true
			},
			columnDefsActive: {
				code: {
					render: (data) => {
						return data;
					}
				},
				startdate: {
					render: (data) => {
						return getDateByFormat(parseInt(data), this.userProperties && this.userProperties.constants);
					}
				},
				ruleacronym: {
					createdCell: (td, cellData, rowData) => {
						let ruleName = this.getRuleName(cellData);
						return (td.innerHTML = `<label class="fw-600" style="color:${rowData.severitycolor}">${ruleName}</label>`);
					}
				},
				isaverage: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			},
			expanded: [],
			singleExpand: false,
			notifications: null,
			loadingAnimation: true,
			elasticDetail: {},
			elasticDetailRounded: {},
			activeFilter: null,
		};
	},
	computed: {
		deviceParameters() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmorganizationid == this.userProperties.organizationid);
		}
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		this.setExternalFilter();
	},
	methods: {
		setExternalFilter() {
			let ruleNames = [];
			if (this.isSuperAdmin) {
				this.$store.getters.getRules && this.$store.getters.getRules.map((obj) => obj.acronym);
			} else {
				this.$store.getters.getRules && this.$store.getters.getRules.filter((rule) => rule.pmorganizationid == this.userProperties.organizationid).map((obj) => obj.acronym);
			}
			this.activeFilter = null;

			if (ruleNames.length > 0) {
				if (this.isSuperAdmin) {
					this.activeFilter = {
						groupOp: 'or',
						rules: [],
						groups: []
					};
					ruleNames.forEach((rule) => {
						this.activeFilter.groups.push({
							groupOp: 'and',
							rules: [{ field: 'ruleacronym', op: 'eq', data: rule }]
						});
					});
				} else {
					this.activeFilter = {
						groupOp: 'or',
						rules: [],
						groups: []
					};
					ruleNames.forEach((rule) => {
						this.activeFilter.groups.push({
							groupOp: 'and',
							rules: [
								{ field: 'ruleacronym', op: 'eq', data: rule },
								{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }
							]
						});
					});
				}
			} else {
				if (!this.isSuperAdmin) {
					this.activeFilter = {
						groupOp: 'and',
						rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
						groups: []
					};
				}
			}
			console.debug('dev hlp', ruleNames, this.activeFilter, this.historicFilter);
		},
		getRuleName(ruleName) {
			let rule = this.$store.getters.getRules && this.$store.getters.getRules.find((rule) => rule.acronym == ruleName);
			console.log('dev hlp rules', ruleName, rule);
			if (rule) return rule.name;
			return ruleName;
		}
	}
};
</script>

<style lang="postcss" scoped></style>
